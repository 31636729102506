// import main SASS file
import '../../styles/scss/style.scss'

// Import Foundation
import 'foundation-sites'

// import JS modules to compile
import './init-foundation'
import './wp-foundation'
import './modules/fancybox'
import './modules/navMenu'
import './modules/parallax'

jQuery($).ready(function() {
    if (navigator.userAgent.match(/android/i)) {
        var viewport1 = document.querySelector("meta[name=viewport]");
        viewport1.setAttribute('content', 'width=device-width, initial-scale=0.5, user-scalable=0, width=720');
    }
    if (navigator.userAgent.match(/ipad/i)) {
        var viewport2 = document.querySelector("meta[name=viewport]");
        viewport2.setAttribute('content', 'width=device-width, initial-scale=0.60, user-scalable=0');
    }
    if (navigator.userAgent.match(/nexus/i)) {
        var viewport3 = document.querySelector("meta[name=viewport]");
        viewport3.setAttribute('content', 'width=device-width, initial-scale=0.77, user-scalable=0, width=720');
    }
    if (navigator.userAgent.match(/Silk/)) {
        var viewport4 = document.querySelector("meta[name=viewport]");
        viewport4.setAttribute('content', 'width=device-width, initial-scale=1.59, user-scalable=0');
    }
    if (navigator.userAgent.match(/iPhone/i)) {
        var viewport5 = document.querySelector("meta[name=viewport]");
        viewport5.setAttribute('content', 'width=device-width, initial-scale=0.5, user-scalable=0, width=640');
    }
    if (navigator.userAgent.match(/BB10/)) {
        var viewport6 = document.querySelector("meta[name=viewport]");
        viewport6.setAttribute('content', 'width=device-width, initial-scale=0.77, user-scalable=0, width=360');
    }
    if (navigator.userAgent.match(/BB30/)) {
        var viewport7 = document.querySelector("meta[name=viewport]");
        viewport7.setAttribute('content', 'width=device-width, initial-scale=0.77, user-scalable=0, width=360');
    }
    if ($(window).width() > 749) {
        if (navigator.userAgent.match(/iPhone/i)) {
            var viewport8 = document.querySelector("meta[name=viewport]");
            viewport8.setAttribute('content', 'width=device-width, initial-scale=0.595, user-scalable=0');
        }
    }
    if ($(window).width() > 749) {
        if (navigator.userAgent.match(/android/i)) {
            var viewport9 = document.querySelector("meta[name=viewport]");
            viewport9.setAttribute('content', 'width=device-width, initial-scale=0.58, user-scalable=0, width=720');
        }
    }
    if (navigator.userAgent.match(/Windows Phone/i)) {
        var viewport10 = document.querySelector("meta[name=viewport]");
        viewport10.setAttribute('content', 'width=device-width, initial-scale=0.77, user-scalable=0, width=720');
    }
    // Navicon
    $(".menu_icon").click(function() {
        $(this).toggleClass("open");
		$("#menu-mobile-navigation").toggle("slide", {
			direction: "right"
		}, 400);
    });
    // Nav Subs
    $("#menu-item-807 > a").click(function() {
		$("#menu-item-807").toggleClass("open");
		$("#menu-item-807 .sub-menu").slideToggle(250);
	});
	$("#menu-item-809 > a").click(function() {
		$("#menu-item-809").toggleClass("open");
		$("#menu-item-809 .sub-menu").slideToggle(250);
	});
	$("#menu-item-816 > a").click(function() {
		$("#menu-item-816").toggleClass("open");
		$("#menu-item-816 .sub-menu").slideToggle(250);
	});
	$("#menu-item-819 > a").click(function() {
		$("#menu-item-819").toggleClass("open");
		$("#menu-item-819 .sub-menu").slideToggle(250);
    });
    $("#menu-item-2501 > a").click(function() {
		$("#menu-item-2501").toggleClass("open");
		$("#menu-item-2501 .sub-menu").slideToggle(250);
    });
    
    // Bold Past Events List Item
    $(function(){
        var url = window.location.href;
        if(url.indexOf('/events/list/?tribe_event_display=past') > -1){
            $(".categories li:last-child a").css('font-weight', '600');
        }
    });
    // Shrink Header On Scroll
    if ($(window).width() > 750) {
        $(window).scroll(function() {
            if ($(this).scrollTop() > 152){  
                $('.header').addClass("shrink");
            }
            else{
                $('.header').removeClass("shrink");
            }
        });
    }
});