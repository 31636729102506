// Nav Menu

// $(document).ready(() => {
//     $('#menu-item-19').on('click', (e) => {
//         e.preventDefault()
//         $('#example-animated-menu').css('display', 'none')
//         scrollToElement('#where-to-buy')
//     })
// })


// function scrollToElement( target ) {
//     var topoffset = 30
//     var speed = 100
//     var destination = $( target ).offset().top - topoffset
//     $('html:not(:animated),body:not(:animated)').animate( { scrollTop: destination}, speed, function() {
//         window.location.hash = target
//     })
//     return false
// }